<template>
  <b-card
    no-body
  >
    <div class="card-header">
      <!-- Title & SubTitle -->
      <div>
        <b-card-title>{{ $attrs.title }}</b-card-title>
        <b-card-sub-title v-if="$attrs['sub-title']">
          {{ $attrs['sub-title'] }}
        </b-card-sub-title>
      </div>
    </div>

    <template v-if="$attrs['no-body'] !== undefined">
      <slot />
    </template>

    <b-card-body v-else>
      <!-- Card Content -->
      <slot />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardTitle, BCardSubTitle, BCardBody,
} from 'bootstrap-vue'
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'

export default {
  components: {
    BCard,
    BCardTitle,
    BCardSubTitle,
    BCardBody,
  },
  inheritAttrs: false,
  props: {
    codeLanguage: {
      default: 'markup',
      type: String,
    },
  },
}
</script>
